import {
  DeleteWithConfirmButton,
  FormTab,
  required,
  SaveButton,
  TextInput,
  Toolbar,
  useGetList,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Flex, ResourceTabbedForm, RowInfoBlock } from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import {
  accessPermission,
  isEmail,
  isEmptyAfterTrimmed,
  isPassword,
  isPasswordConfirm,
  isPhone,
  maxLengthExt,
  onlyLatin,
} from '@Helpers';
import { CheckboxGroupInputStyled } from '@PluginManager/plugins/Promotions/resources/Promocodes/pages/PromocodeForm/styled';
import { useFormContext } from 'react-hook-form';
import { A } from '@mobily/ts-belt';
import { useEffect, useState } from 'react';
import { CompanyName } from '@Helpers/AccessPermissions/interface';

const validateEmail = [required(), isEmail()];
const validatePasswordRequired = [required(), isPassword(8)];
const validateConfirmPasswordRequired = [required(), isPasswordConfirm()];
const validatePassword = isPassword(8);
const validateConfirmPassword = isPasswordConfirm();
const validatePhone = [required(), isPhone()];
const validateFirstName = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  maxLengthExt(60),
];
const validateLastName = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  maxLengthExt(60),
];

const roleCodeToHide = 'dispatcher_superuser';
const rolesFieldName = 'roles';

const RolesContent = ({ roles = [] }: { roles: any[] }) => {
  const { setValue, watch } = useFormContext();
  const selectedRoleIds: number[] = watch(rolesFieldName) ?? [];
  const [prevSelectedRoleIds, setPrevSelectedRoleIds] = useState<number[]>([]);
  const notify = useNotify();
  const translate = useTranslate();
  const courierId = roles.find(({ code }) => code === 'courier')?.id;
  const dispatcherId = roles.find(({ code }) => code === 'dispatcher')?.id;

  useEffect(() => {
    const newSelectedId = A.difference(selectedRoleIds, prevSelectedRoleIds)[0];
    const isCourierAndDispatcherSelected =
      selectedRoleIds.includes(courierId) &&
      selectedRoleIds.includes(dispatcherId);
    const idToRemove =
      newSelectedId === courierId
        ? dispatcherId
        : newSelectedId === dispatcherId
        ? courierId
        : undefined;
    const resultIds = selectedRoleIds.filter((id) => id !== idToRemove);

    setValue(rolesFieldName, resultIds);
    setPrevSelectedRoleIds(resultIds);

    if (isCourierAndDispatcherSelected) {
      notify(translate('ra.validation.noCourierAndDispatcherSameTime'), {
        type: 'warning',
      });
    }
  }, [selectedRoleIds.toString()]);

  return (
    <CheckboxGroupInputStyled
      source={rolesFieldName}
      name={rolesFieldName}
      choices={roles}
      optionText={(choice) => `${choice.name} (${choice.service})`}
      optionValue="id"
      translateChoice={false}
      row={false}
      validate={required()}
    />
  );
};

export const UserForm = () => {
  const record = useRecordContext<CoreUser>();
  const isMobile = useIsMobile();
  const isEditForm = record?.id !== undefined;

  const { data: rolesList = [] } = useGetList(
    ResourceRoutes.roles.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
    }
  );
  const roles = rolesList.filter((role) => {
    if (accessPermission.companyName === CompanyName.ArabianMile) {
      return ![
        'customer_support_specialist',
        'procurement_specialist',
        'marketer',
        'replenishment_specialist',
        'it_support',
        roleCodeToHide,
      ].includes(role.code);
    }

    return role.code !== roleCodeToHide;
  });

  return (
    <ResourceTabbedForm
      record={record}
      toolbar={
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <SaveButton />

          {isEditForm && (
            <DeleteWithConfirmButton confirmContent="ra.message.delete_account" />
          )}
        </Toolbar>
      }
    >
      <FormTab label="settings.pages.users.tabs.general">
        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              fullWidth
              name="email"
              source="email"
              type="email"
              validate={isEditForm ? undefined : validateEmail}
              isRequired={isEditForm}
              disabled={isEditForm}
            />

            {/* {isEditForm && (
              <TextInput
                fullWidth
                name="login"
                source="login"
                validate={validateLogin}
              />
            )}*/}

            <TextInput
              fullWidth
              name="password"
              source="password"
              type="password"
              autoComplete="new-password"
              label={
                isEditForm
                  ? 'settings.pages.users.labels.newPassword'
                  : undefined
              }
              validate={
                isEditForm ? validatePassword : validatePasswordRequired
              }
            />

            <TextInput
              fullWidth
              name="confirmPassword"
              source="confirmPassword"
              type="password"
              label={
                isEditForm
                  ? 'settings.pages.users.labels.confirmNewPassword'
                  : undefined
              }
              validate={
                isEditForm
                  ? validateConfirmPassword
                  : validateConfirmPasswordRequired
              }
            />
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              fullWidth
              name="phone"
              source="phone"
              type="tel"
              validate={validatePhone}
            />

            <TextInput
              fullWidth
              name="firstName"
              source="firstName"
              validate={validateFirstName}
            />

            <TextInput
              fullWidth
              name="lastName"
              source="lastName"
              validate={validateLastName}
            />
          </RowInfoBlock>
        </Flex>
      </FormTab>

      <FormTab label="settings.pages.users.tabs.roles">
        <RolesContent roles={roles} />
      </FormTab>
    </ResourceTabbedForm>
  );
};
