import { useState, useEffect, ChangeEvent } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';

import { Flex } from '@UI';
import { SupplierRow } from '@Plugins/Replenishment/resources/Suppliers/interface';

import { useFocusedColumnContext } from '../../contexts/FocusedColumnContext';
import { Root, SupplierWeekDayColumnClasses } from './styles';
import { SupplierWeekDayColumnProps } from './interfaces';

const SupplierWeekDayColumn = ({ weekDay }: SupplierWeekDayColumnProps) => {
  const translate = useTranslate();

  const params = useParams();
  const supplierRow = useRecordContext<SupplierRow>();
  const targetValue = `${supplierRow[weekDay]?.supply_gap ?? ''}`;
  const id = `${supplierRow.id}.${weekDay}`;

  const {
    focusedId,
    setFocusedId,
    clearFocusedId,
    setDirtyColumns,
    dirtyColumns,
    updateCellValue,
    isUpdating,
    deleteCellValue,
  } = useFocusedColumnContext();

  const [value, setValue] = useState<string>(targetValue);
  const [errors, setErrors] = useState<string | undefined>();

  const handleOpenField = () => {
    setFocusedId(id);
  };

  const handleCloseField = () => {
    clearFocusedId();
  };

  const handleEditCallValue = () => {
    const existingSchedule = supplierRow[weekDay];

    if (!value) {
      if (existingSchedule) {
        deleteCellValue(existingSchedule.id);
      }

      return;
    }

    updateCellValue({
      id: existingSchedule?.id,
      supplier_id: `${params.id}`,
      store_id: supplierRow.id,
      order_day: weekDay + 1,
      supply_gap: isNumber(value) ? Number(value) : null,
    });
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (!dirtyColumns[id]) {
      setDirtyColumns((prev) => ({
        ...prev,
        [id]: e.target.value,
      }));
    }
  };

  const editing = focusedId === id;

  useEffect(() => {
    if (!editing) {
      setValue(targetValue);
    }
  }, [editing, targetValue, setValue]);

  useEffect(() => {
    if (!value) {
      setErrors(undefined);

      return;
    }

    if (isNaN(Number(value)) || value.includes('.')) {
      setErrors(translate('ra.validation.integer'));

      return;
    }

    if (Number(value) < 0) {
      setErrors(translate('ra.validation.minValue', { min: '0' }));

      return;
    }

    setErrors(undefined);
  }, [value]);

  return (
    <Root role="cell" aria-label="Edit cell value">
      {editing ? (
        <>
          <TextField
            label=""
            type="number"
            value={value}
            onChange={handleFieldChange}
            variant="outlined"
            color="primary"
            disabled={isUpdating}
            autoFocus
            className={SupplierWeekDayColumnClasses.field}
            placeholder={translate(
              'replenishment.pages.suppliers.labels.placeholder'
            )}
            error={!!errors}
            helperText={errors}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Flex asColumn={false} alignItems="center">
                    <Tooltip
                      placement="top"
                      title={translate(
                        'replenishment.pages.suppliers.labels.save'
                      )}
                    >
                      <IconButton
                        size="small"
                        color="success"
                        disabled={
                          value === targetValue || isUpdating || !!errors
                        }
                        onClick={handleEditCallValue}
                      >
                        {isUpdating ? (
                          <CircularProgress size="1em" />
                        ) : (
                          <Check />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title={translate(
                        'replenishment.pages.suppliers.labels.close'
                      )}
                    >
                      <IconButton
                        size="small"
                        color="error"
                        onClick={handleCloseField}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Flex>
                </InputAdornment>
              ),
            }}
          />
        </>
      ) : (
        <span
          className={SupplierWeekDayColumnClasses.value}
          onClick={handleOpenField}
        >
          {targetValue || (
            <Typography variant="caption" color="rgba(255, 255, 255, 0.3)">
              {translate('replenishment.pages.suppliers.labels.empty')}
            </Typography>
          )}
        </span>
      )}
    </Root>
  );
};

const isNumber = (value: unknown): value is number => !isNaN(Number(value));

export default SupplierWeekDayColumn;
