import { ArrayField, Datagrid, ImageField, TextField } from 'react-admin';

const OrderItems = () => {
  return (
    <ArrayField source="items">
      <Datagrid bulkActionButtons={false}>
        <ImageField source="image" />
        <TextField source="name" />
        <TextField source="weight" />
      </Datagrid>
    </ArrayField>
  );
};

export default OrderItems;
