import { Plugin } from '@PluginBase';
import Translation from './i18n.json';
import { Icon } from '@UI';
import UsersController from './resources/Users/controller';
import RolesController from './resources/Roles/controller';
import PropertiesController from './resources/Properties/controller';
import CommodityGroupPages from './resources/CommodityGroups/controller';
import ShippingMethodsController from './resources/ShippingMethods/controller';
import FiltersController from './resources/Filters/controller';
import SubscriptionsSettingsController from './resources/SubscriptionsSettings/controller';

const Settings = new Plugin({
  i18n: Translation,
  resources: [
    UsersController,
    RolesController,
    PropertiesController,
    CommodityGroupPages,
    ShippingMethodsController,
    FiltersController,
    SubscriptionsSettingsController,
  ],
  name: 'Settings',
  rootMenu: {
    caption: {
      translationKey: 'settings.caption',
    },
    icon: <Icon type="settings" />,
  },
});

export default Settings;
