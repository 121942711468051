import { Identifier, useTranslate } from 'react-admin';
import { ListItemDrawer } from '@Widgets/ListItemDrawer/ListItemDrawer';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { ShowFromContainerStyled } from '@Widgets/styles';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { LIST_COLUMNS, FILTERS } from './constants';
import { accessPermission } from '@Helpers';
import { useSearchParams } from 'react-router-dom';

export const ListPage = () => {
  const translate = useTranslate();
  const [searchParams] = useSearchParams();

  const columns = accessPermission.company?.isCustomerOrderIdHidden
    ? LIST_COLUMNS.filter((item) => item.source !== 'multiple_order_id')
    : LIST_COLUMNS;

  const customerId = searchParams.get('customerId');

  const onRowClickHandler = (id: Identifier) =>
    `${ResourceRoutes.orders.routePath}/${id}?${searchParams.toString()}`;

  return (
    <>
      <ResourceList<CoreOrder>
        columns={columns}
        showDeleteButton={false}
        exporter={false}
        filters={FILTERS}
        filterDefaultValues={
          customerId
            ? {
                search: customerId,
              }
            : undefined
        }
        defaultSort={{
          field: 'created_at',
          order: 'DESC',
        }}
        getItemCardCaption={(record) =>
          `${translate('orders.pages.labels.orderId')} ${record.order_id}`
        }
        rowClick={onRowClickHandler}
      />

      <ListItemDrawer route={ResourceRoutes.orders.routePath}>
        {({ entity }) =>
          entity ? (
            <ShowFromContainerStyled id={entity} emptyWhileLoading>
              <OrderDetails />
            </ShowFromContainerStyled>
          ) : (
            <div />
          )
        }
      </ListItemDrawer>
    </>
  );
};
