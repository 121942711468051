import { useState, useEffect, useCallback } from 'react';
import {
  useTranslate,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import pluginManager from '@PluginManager/PluginManager';

import SubMenu from './SubMenu/SubMenu';
import { DEFAULT_SCROLL_NAVIGATION, STORAGE_EXPANDED_KEY } from './constant';
import { MenuContainerStyled } from './styles';

const Menu = ({ dense = false }: MenuProps) => {
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [expandedMenuObj, setExpandedMenuObj] = useState<Record<
    string,
    boolean
  > | null>(null);

  const { pathname } = useLocation();

  const handleExpanded = (translationKey: string, state: boolean) => {
    window.localStorage.setItem(
      'expandedMenuTranslationKeys',
      JSON.stringify({
        ...JSON.parse(
          window.localStorage.getItem('expandedMenuTranslationKeys') || '{}'
        ),
        [translationKey]: state,
      })
    );
  };

  const getIsExpanded = useCallback(
    (key: string) => {
      const value = expandedMenuObj?.[key];

      if (typeof value !== 'boolean') {
        return false;
      }

      return value;
    },
    [expandedMenuObj]
  );

  useEffect(() => {
    const expandedMenuObj = getExpandedKeys();

    const currentMenu = pluginManager.pluginsMenu.find(
      (item) =>
        item.rootMenu?.route === pathname ||
        item.items.some((_item) => _item.route === pathname)
    );

    const preparedExpandedKeys = currentMenu?.rootMenu
      ? {
          ...expandedMenuObj,
          [currentMenu?.rootMenu.caption.translationKey]: true,
        }
      : expandedMenuObj;

    window.localStorage.setItem(
      STORAGE_EXPANDED_KEY,
      JSON.stringify(preparedExpandedKeys)
    );
    setExpandedMenuObj(preparedExpandedKeys);
  }, [pathname]);

  if (expandedMenuObj === null) return <></>;

  return (
    <MenuContainerStyled open={open}>
      {pluginManager.pluginsMenu.map((menuConfig) => {
        const { rootMenu, items } = menuConfig;

        if (!items?.length) {
          return null;
        }

        const menuItems = items.map((props) => {
          const route =
            typeof props.route === 'function' ? props.route() : props.route;

          return (
            <MenuItemLink
              id={route}
              key={`${props.caption.translationKey}-${props.route}`}
              to={route}
              state={DEFAULT_SCROLL_NAVIGATION}
              primaryText={translate(
                props.caption.translationKey,
                props.caption.options
              )}
              leftIcon={props.icon}
              dense={dense}
              target={route.includes('http') ? '_blank' : undefined}
            />
          );
        });

        if (rootMenu) {
          const isExpanded = getIsExpanded(rootMenu.caption.translationKey);

          return (
            <SubMenu
              key={`${rootMenu.caption.translationKey}_${isExpanded}`}
              name={translate(
                rootMenu.caption.translationKey,
                rootMenu.caption.options
              )}
              icon={rootMenu.icon}
              dense={dense}
              isExpanded={isExpanded}
              handleExpanded={(state) =>
                handleExpanded(rootMenu.caption.translationKey, state)
              }
            >
              {menuItems}
            </SubMenu>
          );
        }

        return menuItems;
      })}
    </MenuContainerStyled>
  );
};

const getExpandedKeys = () => {
  const expandedMenuTranslationKeys =
    window.localStorage.getItem(STORAGE_EXPANDED_KEY);

  if (expandedMenuTranslationKeys) {
    return JSON.parse(expandedMenuTranslationKeys);
  }

  return pluginManager.expandedMenuState;
};

export default Menu;
