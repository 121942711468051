import { TextInput, useTranslate } from 'react-admin';

import { Flex, ResourceInputNumber, ResourceSwitcher, RowInfoBlock } from '@UI';

export const SubscriptionSettingsForm = (): JSX.Element => {
  const t = useTranslate();

  return (
    <RowInfoBlock asColumn flex={1} justifyContent="flex-start" fullWidth>
      <Flex asColumn gap={1} fullWidth>
        <ResourceSwitcher
          name="settings.data.autoRemovalAfterPurchase"
          labelKey="settings.pages.subscriptionsSettings.labels.autoRemovalAfterPurchase"
        />

        <ResourceInputNumber
          source="settings.data.reminderCount"
          isInteger
          fullWidth
          label="settings.pages.subscriptionsSettings.labels.reminderCount"
        />

        <TextInput
          source="settings.data.companyUrl"
          name="settings.data.companyUrl"
          label="settings.pages.subscriptionsSettings.labels.companyUrl"
          fullWidth
          validate={(value) => {
            const urlPattern =
              /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/;

            return urlPattern.test(value)
              ? undefined
              : t(
                  'settings.pages.subscriptionsSettings.errors.MUST_BE_VALID_URL'
                );
          }}
        />
      </Flex>
    </RowInfoBlock>
  );
};
