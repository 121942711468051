import { Plugin } from '@PluginBase';
import Translation from './i18n.json';

import subscriptions from './resources/Subscriptions/controller';

const Subscriptions = new Plugin({
  name: 'Subscriptions',
  resources: [subscriptions],
  i18n: Translation,
});

export default Subscriptions;
