import { SyntheticEvent, useState } from 'react';
import { DateField, useRecordContext, useTranslate } from 'react-admin';
import { Tab, Tabs, Typography } from '@mui/material';

import { Flex } from '@UI';
import { StatusBadgeResource } from '@Plugins/Orders/resources/Orders/pages/components/StatusBadgeResource/StatusBadgeResource';

import { General } from './tabs/General';
import { OrderItems } from './tabs/OrderItems';

const OrderDetails = () => {
  const record = useRecordContext<CoreDeliveryOrder>();
  const translate = useTranslate();

  const [currentTab, setCurrentTab] = useState(0);

  const onChangeTabHandler = (event: SyntheticEvent, newValue: number) =>
    setCurrentTab(newValue);

  return (
    <Flex asColumn maxWidth={500} width="95vw">
      <Flex padding={1} alignItems="flex-start" justifyContent="space-between">
        <Flex asColumn>
          <Typography variant="h5">{record.order_id}</Typography>

          <Typography variant="subtitle2">
            {`${translate('orders.pages.labels.createdAt')}: `}

            <DateField source="created_at" />
          </Typography>
        </Flex>

        <Flex asColumn>
          <StatusBadgeResource />
        </Flex>
      </Flex>

      <Tabs
        value={currentTab}
        onChange={onChangeTabHandler}
        variant="scrollable"
      >
        <Tab label={translate('orders.pages.tabs.general')} />

        <Tab label={translate('orders.pages.tabs.orderItems')} />
      </Tabs>

      <Flex asColumn padding={1}>
        {currentTab === 0 && <General />}
        {currentTab === 1 && <OrderItems />}
      </Flex>
    </Flex>
  );
};

export default OrderDetails;
