import { Edit, SaveButton, SimpleForm, Toolbar, WithRecord } from 'react-admin';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { FormErrorBoundary } from '@UI';

import { ReferralConfig, ReferralProgramFormProps } from '../interfaces';
import { SubscriptionSettingsForm } from '../components/forms/SubscriptionSettingsForm';
import { accessPermission } from '@ROOT/helpers';

export const ListPage = (): JSX.Element => {
  return (
    <Edit
      id="unknown"
      sx={{ mt: 1 }}
      resource={ResourceRoutes.subscriptionSettings.resourcePath}
      mutationMode="pessimistic"
    >
      <WithRecord<ReferralConfig<ReferralProgramFormProps>>
        render={(record) => {
          return (
            <SimpleForm
              record={record}
              toolbar={
                accessPermission.checkPermission('Settings', 'U') ? (
                  <Toolbar>
                    <SaveButton />
                  </Toolbar>
                ) : (
                  <></>
                )
              }
            >
              <SubscriptionSettingsForm />

              <FormErrorBoundary />
            </SimpleForm>
          );
        }}
      />
    </Edit>
  );
};
