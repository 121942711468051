import { ListColumn, Filter } from '@Widgets/ResourceList/interface';
import { UserInfo } from '@Plugins/Orders/resources/Orders/pages/components/UserInfo/UserInfo';
import { StatusBadgeResource } from '@Plugins/Orders/resources/Orders/pages/components/StatusBadgeResource/StatusBadgeResource';

import { PromisedDeliveryTimeColumn } from '../../components/PromisedDeliveryTimeColumn';
import { DeliveryServiceColumn } from '../../components/DeliveryServiceColumn';

export const OrderStatuses: SelectOption[] = [
  { id: 'delivery_created', name: 'orders.statuses.delivery_created' },
  { id: 'in_delivery', name: 'orders.statuses.in_delivery' },
  { id: 'delivered', name: 'orders.statuses.delivered' },
  { id: 'delivered_partially', name: 'orders.statuses.delivered_partially' },
  { id: 'delivery_failed', name: 'orders.statuses.delivery_failed' },
];

export const LIST_FILTERS: Filter[] = [
  {
    source: 'search',
    type: 'search',
  },
  {
    source: 'status',
    type: 'choose-multi',
    choices: OrderStatuses,
    label: 'orders.pages.labels.status',
  },
];

export const LIST_COLUMNS: ListColumn<CoreDeliveryOrder>[] = [
  {
    source: 'order_id',
    type: 'text',
    label: 'delivery.pages.deliveryOrders.columns.orderNumber',
    sortable: false,
  },
  {
    source: 'sla_delivery_by_max',
    type: 'custom',
    label: 'delivery.pages.deliveryOrders.columns.promisedDeliveryTime',
    customComponent: <PromisedDeliveryTimeColumn />,
    sortable: false,
  },
  {
    source: 'warehouse_code',
    type: 'text',
    label: 'delivery.pages.deliveryOrders.columns.origin',
    sortable: false,
  },
  {
    label: 'delivery.pages.deliveryOrders.columns.deliveryAddress',
    source: 'address',
    type: 'custom',
    customComponent: (
      <UserInfo
        type="address"
        key="address"
        label="orders.pages.labels.deliveryAddress"
        hasPhoneFormat={false}
      />
    ),
    sortable: false,
  },
  {
    label: 'delivery.pages.deliveryOrders.columns.deliveryService',
    source: 'id',
    type: 'custom',
    customComponent: <DeliveryServiceColumn />,
    sortable: false,
  },
  {
    type: 'custom',
    label: 'delivery.pages.deliveryOrders.columns.status',
    source: 'status',
    customComponent: <StatusBadgeResource key="status" />,
  },
];
