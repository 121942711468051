import { useTranslate } from 'react-admin';

import { RowInfoBlock } from '@UI';
import { UserInfo } from '@Plugins/Orders/resources/Orders/pages/components/UserInfo/UserInfo';

const General = () => {
  const translate = useTranslate();

  return (
    <>
      <RowInfoBlock titleKey="delivery.pages.deliveryOrders.labels.sendTo">
        <UserInfo type="address" hasPhoneFormat={false} />
      </RowInfoBlock>
      <RowInfoBlock titleKey="delivery.pages.deliveryOrders.labels.deliveryService">
        {translate('delivery.pages.deliveryOrders.labels.ownDelivery')}
      </RowInfoBlock>
    </>
  );
};

export default General;
