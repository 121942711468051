import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';

const PromisedDeliveryTimeColumn = () => {
  const record = useRecordContext<CoreDeliveryOrder>();

  const { from, to } = useMemo(() => {
    const minDate = new Date(Number(record.sla_delivery_by_min) * 1000);
    const maxDate = new Date(Number(record.sla_delivery_by_max) * 1000);

    if (minDate.getDate() !== maxDate.getDate()) {
      return {
        from: `${minDate.toLocaleDateString()} ${minDate.toLocaleTimeString()}`,
        to: `${maxDate.toLocaleDateString()} ${maxDate.toLocaleTimeString()}`,
      };
    }

    return {
      from: `${minDate.toLocaleDateString()} ${minDate.toLocaleTimeString()}`,
      to: `${maxDate.toLocaleTimeString()}`,
    };
  }, [record]);

  return (
    <span>
      {from} - {to}
    </span>
  );
};

export default PromisedDeliveryTimeColumn;
