import { categoryCrud } from './api';

const removeEmptyKeys = (data: Record<string, unknown>) =>
  Object.entries(data).reduce<Record<string, unknown>>((memo, [key, value]) => {
    if (!['number', 'boolean'].includes(typeof value) && !value) {
      return memo;
    }

    return { ...memo, [key]: value };
  }, {});

const fetchCategoryData = async (
  id: number
): Promise<CoreCategory | undefined> => {
  try {
    const parentResponse = await categoryCrud.getOne<{
      data: CoreCategory;
    }>({ id });

    const { data } = parentResponse || {};

    return data;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
};

export const handleParentId = async (parentId: number) => {
  const parentCategoryData = await fetchCategoryData(parentId);

  if (!parentCategoryData) {
    return;
  }

  let firstLvlParent: CoreCategory | undefined;

  if (parentCategoryData.parentId) {
    firstLvlParent = await fetchCategoryData(parentCategoryData.parentId);
  }

  return removeEmptyKeys({
    parentCategory: parentCategoryData,
    landingPage: parentCategoryData?.landingPage || firstLvlParent?.landingPage,
    displayType: parentCategoryData?.displayType || firstLvlParent?.displayType,
  });
};
