import { GetListResult, GetOneResult } from 'react-admin';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import deliveryOrdersCRUD from './restAPI/DeliveryOrdersCRUD';
import { ListPage } from './pages/ListPage';

const DeliveryOrdersController = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.deliveryOrders.routePath,
    caption: {
      translationKey: 'delivery.pages.deliveryOrders.caption',
    },
    icon: <MonetizationOnIcon />,
  },
  resourceRoute: {
    name: ResourceRoutes.deliveryOrders.resourcePath,
    list: <ListPage />,
  },
  dataProvider: {
    getList: async (_, params) => {
      const response = await deliveryOrdersCRUD.list(params);

      return {
        ...response,
        data: response.data.map((item) => ({
          ...item,
          address: {
            ...item.address,
            ...item.location,
          },
        })),
      } as GetListResult;
    },
    getOne: async (_, params) => {
      const response = await deliveryOrdersCRUD.getOne(params);

      if (!response) {
        return { data: undefined };
      }

      const deliveryOrderResponse = response as CoreDeliveryOrder;

      const deliveryOrder = {
        ...deliveryOrderResponse,
        _id: deliveryOrderResponse.id,
        id: deliveryOrderResponse.order_uuid,
        created_at: Number(deliveryOrderResponse.created_at) * 1000,
        address: {
          ...deliveryOrderResponse.address,
          ...deliveryOrderResponse.location,
        },
      };

      return {
        data: deliveryOrder,
      } as GetOneResult;
    },
  },
});

export default DeliveryOrdersController;
