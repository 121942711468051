import { styled } from '@mui/material/styles';

// @ts-ignore
import Image from '@Assets/company-logos/arabian-mile.png';

const Root = styled('span')`
  width: 84px;
  height: 40px;
  background-image: url('${Image}');
  background-position: center;
  background-size: contain;
`;

const ArabianMile = () => <Root />;

export default ArabianMile;
