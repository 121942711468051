import { useMemo } from 'react';
import { useGetList, useLocaleState } from 'react-admin';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { PER_PAGE_VALUE } from '@Widgets/ResourceNestedList/constants';
import { RTL_SUPPORT_LANGUAGES } from '@ROOT/layout/themeProvider';

export interface SimpleParentCategory {
  id: number | string;
  name: string;
  displayType?: string;
  landingPage?: string;
  parentId: Nullable<number>;
}

export const useParentCategoryList = (
  categoryId?: number | string
): SimpleParentCategory[] => {
  const { data: categoriesAPI = [] } = useGetList<CoreCategory>(
    ResourceRoutes.catalog.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: PER_PAGE_VALUE,
      },
      sort: {
        field: 'sorting',
        order: 'ASC',
      },
    }
  );
  const [locale] = useLocaleState();
  const isRTL = RTL_SUPPORT_LANGUAGES.includes(locale);

  return useMemo(() => {
    if (!categoriesAPI.length) {
      return [];
    }

    const firstLvl = categoriesAPI.filter((item) => {
      if (categoryId !== undefined) {
        return !item.parentId && item.id !== categoryId;
      }

      return !item.parentId;
    });

    return firstLvl
      .map(({ id, name, nameAr, displayType, landingPage }) => {
        const secondLvl = categoriesAPI.filter((item) => {
          if (categoryId !== undefined) {
            return item.parentId === id && item.id !== categoryId;
          }

          return item.parentId === id;
        });
        const localizedParentName = isRTL && nameAr ? nameAr : name;

        const children = secondLvl.map((item) => {
          const localizedChildName =
            isRTL && item.nameAr ? item.nameAr : item.name;

          return {
            id: item.id,
            name: `${localizedParentName} / ${localizedChildName}`,
            displayType,
            landingPage,
            parentId: item.parentId,
          };
        });

        return [
          {
            id,
            name: localizedParentName,
            displayType,
            landingPage,
            parentId: null,
          },
          ...children,
        ];
      })
      .flat();
  }, [categoriesAPI]);
};
