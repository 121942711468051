import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import { LIST_COLUMNS, FILTERS } from '../../constants';
import { useGroupedCategoryList } from '@ROOT/hooks/useGroupedCategoryList';
import { Root } from './styled';

const ListPage = () => {
  const groupedCategoryList = useGroupedCategoryList();

  const getFilters = () =>
    FILTERS.map((item) => {
      if (item.source === 'categoryId') {
        return { ...item, choices: groupedCategoryList };
      }

      return item;
    });

  return (
    <Root>
      <ResourceList
        actions={false}
        showDeleteButton={false}
        columns={LIST_COLUMNS}
        filters={getFilters()}
        getItemCardCaption={() => ''}
        defaultSort={{
          field: 'count',
          order: 'DESC',
        }}
        bulkActionButtons={false}
        disableSyncWithLocation={false}
        rowClick={false}
        empty={false}
      />
    </Root>
  );
};

export default ListPage;
