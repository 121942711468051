import { useRecordContext, useTranslate } from 'react-admin';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

const OrdersLink = () => {
  const { id } = useRecordContext<CoreCustomer>();
  const translate = useTranslate();

  return (
    <MuiLink component={Link} to={`/orders/status?customerId=${id}`}>
      {translate('customers.pages.customers.labels.toOrders')}
    </MuiLink>
  );
};

export default OrdersLink;
