import { GetOneResult, UpdateParams, UpdateResult } from 'react-admin';

import { Icon } from '@UI';
import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { ListPage } from './pages/ListPage';
import {
  StockSubscriptionResp,
  SubscriptionsSettinsConfig,
  SubscriptionsSettinsUpdateConfig,
} from './interfaces';
import { subscriptionsSettingsService } from './api';

const subscriptionsSettingsController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.subscriptionSettings.resourcePath,
    list: ListPage,
  },
  menuItem: {
    route: ResourceRoutes.subscriptionSettings.routePath,
    caption: {
      translationKey: 'settings.pages.subscriptionsSettings.caption',
    },
    icon: <Icon type="notificationsCircle" />,
  },
  dataProvider: {
    getOne: async (): Promise<GetOneResult> => {
      const response: StockSubscriptionResp = {
        id: 'unknown',
        settings: null,
      };

      try {
        const { data } =
          await subscriptionsSettingsService.getCompanySubscriptionConfig();

        response.settings = data;
      } catch (error) {
        console.error('settings', error);
        response.settings = {
          reminderCount: 1,
          autoRemovalAfterPurchase: false,
          companyUrl: '',
        };
      }

      return {
        data: response,
      };
    },

    update: async (
      resource,
      params: UpdateParams<SubscriptionsSettinsUpdateConfig>
    ): Promise<UpdateResult> => {
      const { data } = params;

      if (params.data.settings?.data) {
        if (params.data.settings?.data.id) {
          const updated =
            await subscriptionsSettingsService.updateSubscriptionSettings(
              params.data.settings?.data.id,
              params.data.settings?.data
            );

          if (updated.data) {
            data.settings = {
              data: {
                ...(updated.data.settings as SubscriptionsSettinsConfig),
              },
            };
          }
        } else {
          const created =
            await subscriptionsSettingsService.createSubscriptionSettings(
              params.data.settings?.data
            );

          if (created.data) {
            data.settings = {
              data: {
                ...(created.data.settings as SubscriptionsSettinsConfig),
              },
            };
          }
        }
      }

      return {
        data: {
          id: 'unknown',
          ...data,
        },
      };
    },
  },
});

export default subscriptionsSettingsController;
