import { RequestAPI } from '@RestApi';
import { ReplenishmentApiResource } from '@Plugins/Replenishment/apiRoutes';

import { UpdateSupplyGapRequest, UpdateSupplyResponse } from './interface';

export const requestSupplyGapUpdate = async (
  data: UpdateSupplyGapRequest
): Promise<UpdateSupplyResponse> => {
  const { id, ...restData } = data;

  if (id) {
    return RequestAPI.put(
      `${ReplenishmentApiResource.schedules}/${id}`,
      restData
    );
  }

  return RequestAPI.post(ReplenishmentApiResource.schedules, restData);
};

export const requestSupplyGapDelete = async (id: string): Promise<boolean> => {
  try {
    await RequestAPI.delete(`${ReplenishmentApiResource.schedules}/${id}`);

    return true;
  } catch (e) {
    return false;
  }
};
