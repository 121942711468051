import { ChangeEvent, useEffect } from 'react';
import {
  FormDataConsumer,
  NumberInput,
  RaRecord,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
  useTranslate,
  Validator,
} from 'react-admin';
import { useController, useFormContext } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { InfoRounded, Add } from '@mui/icons-material';
import { Tooltip, Button } from '@mui/material';

import {
  Flex,
  ResourceAutocompleteInput,
  ResourceColorInput,
  RowInfoBlock,
  ResourceSwitcher,
} from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { slugify } from '@Helpers/StringUtils';
import { useParentCategoryList } from '@Hooks/useParentCategoryList';
import { CompanyName } from '@Helpers/AccessPermissions/interface';
import { accessPermission, isEmptyAfterTrimmed, maxLengthExt } from '@Helpers';
import { cannotBeActivated } from '@Helpers/FormValidator/FormValidator';

import {
  DEEP_LINK_PREFIX,
  DISPLAY_TYPE,
  LANDING_PAGE,
  TILE_SIZE,
  REQUIRED_WITH_IMAGE_DISPLAY_TYPE,
} from '../constants';
import { Description } from './Description';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export const General = () => {
  const deepLink = useController({ name: 'deepLink' });
  const url = useController({ name: 'slug' });
  const previewImage = useController({ name: 'previewImage' });
  const { setValue, clearErrors } = useFormContext();
  const record = useRecordContext<CoreCategory>();
  const isMobile = useIsMobile();
  const categoryList = useParentCategoryList(record?.id);
  const translate = useTranslate();
  const isEdit = !!record?.id;
  const isSubcategoryButtonShown =
    // eslint-disable-next-line
    isEdit && (!record.parentCategory || !record.parentCategory.parentId);

  const navigate = useNavigate();
  const { state } = useLocation();

  function onNameChangedHandler(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const { id } = record || {};

    const formattedUrl = !id ? slugify(value) : `${slugify(value)}-${id}`;

    deepLink.field.onChange(`${DEEP_LINK_PREFIX}${formattedUrl}`);
    url.field.onChange(formattedUrl);
  }

  function onUrlChangedHandler(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const formattedUrl = value.trim().toLowerCase().replace(/\s+/gi, '-');

    deepLink.field.onChange(`${DEEP_LINK_PREFIX}${formattedUrl}`);
  }

  const handleDisplayTypeChange = (
    e: RaRecord | ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target?.value;

    if (Object.values(REQUIRED_WITH_IMAGE_DISPLAY_TYPE).includes(val)) {
      const { value } = previewImage.field;

      if (!value?.url) {
        setValue('active', false);
      }
    }
  };

  const getNameLabel = () => {
    if (!accessPermission.company?.isAdvancedCategory) {
      return 'catalogue.pages.categories.labels.name';
    }

    return (
      <Flex gap={1} alignItems="center">
        <span>{translate('catalogue.pages.categories.labels.name')}*</span>
        <Tooltip
          title={translate('catalogue.pages.categories.labels.maxLength', {
            length: 40,
          })}
          placement="top"
        >
          <InfoRounded fontSize="small" />
        </Tooltip>
      </Flex>
    );
  };

  const handleCategoryChange = (categoryId: number | string) => {
    if (typeof categoryId !== 'number' && !categoryId) {
      setValue('parentCategory', null);
      setValue('landingPage', null);
      setValue('displayType', null);

      return;
    }

    const targetCategory = categoryList.find((item) => item.id === categoryId);

    setValue('parentCategory', targetCategory);

    if (targetCategory?.landingPage) {
      setValue('landingPage', targetCategory.landingPage);
    }

    if (targetCategory?.displayType) {
      setValue('displayType', targetCategory.displayType);
    }
  };

  const handleLandingPageChange = (
    e: RaRecord | ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target?.value;

    if (!value) {
      setValue('displayType', null, { shouldDirty: true });
      clearErrors('displayType');
    }
  };

  const handleAddSubcategory = () => {
    if (!isSubcategoryButtonShown) {
      return;
    }

    navigate(`${ResourceRoutes.catalog.routePath}/create`, {
      state: { parentId: record.id },
    });
  };

  useEffect(() => {
    if (!isEdit && state?.parentId && categoryList.length > 0) {
      setValue('parentId', state.parentId);
      handleCategoryChange(state.parentId);
    }
  }, [isEdit, state, categoryList]);

  return (
    <>
      <Flex
        asColumn={isMobile}
        fullWidth
        gap={1}
        justifyContent="space-between"
      >
        <RowInfoBlock>
          <ResourceSwitcher
            name="active"
            labelKey="catalogue.pages.categories.labels.active"
            validate={cannotBeActivated(
              'catalogue.pages.categories.validation.imageRequiredForActivate'
            )}
          />
        </RowInfoBlock>

        {isSubcategoryButtonShown && (
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleAddSubcategory}
            >
              Add subcategory
            </Button>
          </div>
        )}
      </Flex>

      <Flex asColumn={isMobile} fullWidth gap={1}>
        <RowInfoBlock flex={1} justifyContent="flex-start">
          <TextInput
            onChange={onNameChangedHandler}
            source="name"
            name="name"
            label={getNameLabel()}
            fullWidth
            validate={[
              ...validateName,
              maxLengthExt(
                accessPermission.company?.isAdvancedCategory ? 40 : 246,
                'catalogue.pages.categories.validation.maxLength'
              ),
            ]}
          />

          {accessPermission.company?.categoryHaveDescription && <Description />}

          {accessPermission.company?.categoryHaveColorCustomization && (
            <ResourceColorInput
              labelKey="catalogue.pages.categories.labels.tileBgColor"
              name="backgroundColor"
              defaultColors={
                accessPermission.companyName === CompanyName.CityDrinks
                  ? cdDefaultColors
                  : undefined
              }
            />
          )}

          <TextInput
            onChange={onUrlChangedHandler}
            source="slug"
            name="slug"
            label="catalogue.pages.categories.labels.url"
            fullWidth
            validate={required()}
          />
        </RowInfoBlock>

        <RowInfoBlock flex={1} justifyContent="flex-start">
          <NumberInput
            source="sorting"
            name="sorting"
            label="catalogue.pages.categories.labels.sorting"
            fullWidth
            validate={required()}
          />

          <FormDataConsumer>
            {({ formData }) => (
              <>
                <ResourceAutocompleteInput
                  translateChoice
                  choices={LANDING_PAGE}
                  source="landingPage"
                  name="landingPage"
                  label="catalogue.pages.categories.labels.landingPage"
                  fullWidth
                  onChange={handleLandingPageChange}
                  disabled={!!formData.parentId}
                  filterSelectedOptions={false}
                />

                <ResourceAutocompleteInput
                  translateChoice
                  choices={DISPLAY_TYPE.filter((option) =>
                    option.landingPage.includes(formData.landingPage)
                  )}
                  source="displayType"
                  name="displayType"
                  label="catalogue.pages.categories.labels.displayType"
                  fullWidth
                  onChange={handleDisplayTypeChange}
                  disabled={!formData.landingPage || !!formData.parentId}
                  validate={validateDisplayType}
                  filterSelectedOptions={false}
                />

                <ResourceAutocompleteInput
                  source="parentId"
                  name="parentId"
                  choices={categoryList}
                  defaultValue={null}
                  label="catalogue.pages.categories.labels.parentCategory"
                  fullWidth
                  onChange={handleCategoryChange}
                  disabled={!formData.parentId && !!formData.landingPage}
                />
              </>
            )}
          </FormDataConsumer>

          <SelectInput
            choices={TILE_SIZE}
            source="tileSize"
            name="tileSize"
            label="catalogue.pages.categories.labels.tileSize"
            fullWidth
            validate={required()}
          />

          <TextInput
            disabled
            source="deepLink"
            name="deepLink"
            label="catalogue.pages.categories.labels.deepLink"
            fullWidth
          />
        </RowInfoBlock>
      </Flex>
    </>
  );
};

const validateName = [required(), isEmptyAfterTrimmed()];
const validateDisplayType: Validator[] = [
  (value, { landingPage }) => {
    if (landingPage && !value) {
      return 'catalogue.pages.categories.validation.displayTypeRequired';
    }
  },
];

const cdDefaultColors: string[] = [
  '#FDF3F3',
  '#FEF7EB',
  '#EAF6FF',
  '#F6F2FF',
  '#F7F6F6',
];
