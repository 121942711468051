import { Filter, ListColumn } from '@Widgets/ResourceList/interface';

import { VerificationLink } from './components/VerificationLink';
import { TruncatedContentColumn } from './components/TruncatedContentColumn';
import { EmailColumn } from './components/EmailColumn';
import { PhoneColumn } from './components/PhoneColumn';
import { StatusColumn } from './components/StatusColumn';

import { KYCVerificationStatus } from './interfaces';
import { OrdersLink } from './components/OrdersLink';

export const API_ENDPOINT = 'customer/v1/admin/customers';

export const COLUMN_CONTENT_MAX_LENGTH = 30;

export const LIST_COLUMNS: ListColumn<CoreCustomer>[] = [
  {
    type: 'custom',
    customComponent: (
      <TruncatedContentColumn<CoreCustomer>
        source={({ firstName, lastName }) => {
          const fullName = [firstName, lastName].join(' ');

          return (
            fullName.trim() || 'customers.pages.customers.labels.notSpecified'
          );
        }}
      />
    ),
    source: 'firstName',
    label: 'customers.pages.customers.columns.name',
    sortable: false,
  },
  {
    type: 'custom',
    source: 'kycVerificationStatus',
    label: 'customers.pages.customers.columns.status',
    sortable: false,
    customComponent: <StatusColumn />,
  },
  {
    type: 'custom',
    customComponent: <VerificationLink />,
    source: 'kycVerificationUrl',
    label: 'customers.pages.customers.columns.link',
    sortable: false,
  },
  {
    type: 'custom',
    source: 'phone',
    label: 'customers.pages.customers.columns.phone',
    sortable: false,
    customComponent: <PhoneColumn />,
  },
  {
    type: 'custom',
    customComponent: <EmailColumn />,
    source: 'email',
    label: 'customers.pages.customers.columns.email',
    sortable: false,
  },
  {
    type: 'date',
    source: 'createdAt',
    label: 'customers.pages.customers.columns.createdAt',
  },
  {
    type: 'date',
    source: 'updatedAt',
    label: 'customers.pages.customers.columns.updatedAt',
  },
  {
    type: 'custom',
    customComponent: <OrdersLink />,
    source: 'id',
    label: 'customers.pages.customers.columns.customerOrders',
    sortable: false,
  },
];

export const VERIFICATION_STATUS_CHOICES: SelectOptionTyped<CoreKYCVerificationStatus>[] =
  [
    {
      name: 'customers.pages.customers.kycVerification.not_verified',
      id: KYCVerificationStatus.NOT_VERIFIED,
    },
    {
      name: 'customers.pages.customers.kycVerification.started',
      id: KYCVerificationStatus.STARTED,
    },
    {
      name: 'customers.pages.customers.kycVerification.in_progress',
      id: KYCVerificationStatus.IN_PROGRESS,
    },
    {
      name: 'customers.pages.customers.kycVerification.resubmission_requested',
      id: KYCVerificationStatus.RESUBMISSION_REQUESTED,
    },
    {
      name: 'customers.pages.customers.kycVerification.verified',
      id: KYCVerificationStatus.VERIFIED,
    },
    {
      name: 'customers.pages.customers.kycVerification.declined',
      id: KYCVerificationStatus.DECLINED,
    },
  ];

export const FILTERS: Filter[] = [
  {
    source: 'search',
    type: 'search',
    label: 'customers.pages.customers.labels.search',
  },
  {
    source: 'kycVerificationStatus',
    label: 'customers.pages.customers.labels.verificationStatus',
    type: 'choose',
    choices: VERIFICATION_STATUS_CHOICES,
    emptyText: 'customers.pages.customers.labels.allStatuses',
  },
];
