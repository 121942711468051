import { defaultRenderGroup } from '@ROOT/pluginManager/plugins/Catalogue/resources/Products/components/CategorySelect/CategorySelect';
import { Filter, ListColumn } from '@Widgets/ResourceList/interface';

export const API_ENDPOINT = '/customer/v1/admin/stock-subscriptions';

export const COLUMN_CONTENT_MAX_LENGTH = 30;

export const LIST_COLUMNS: ListColumn<CoreNotifyMeSubscription>[] = [
  {
    type: 'text',
    source: 'product_name',
    label: 'subscriptions.pages.subscriptions.columns.product',
    sortable: false,
  },
  {
    type: 'text',
    source: 'product_id',
    label: 'subscriptions.pages.subscriptions.columns.product_id',
    sortable: false,
  },
  {
    type: 'text',
    source: 'sku',
    label: 'subscriptions.pages.subscriptions.columns.sku',
    sortable: false,
  },
  {
    type: 'text',
    source: 'category_name',
    label: 'subscriptions.pages.subscriptions.columns.category',
    sortable: false,
  },
  {
    type: 'text',
    source: 'count',
    label: 'subscriptions.pages.subscriptions.columns.subs',
    sortable: true,
  },
];

export const FILTERS: Filter[] = [
  {
    source: 'categoryId',
    type: 'autocomplete',
    choices: [],
    label: 'subscriptions.pages.subscriptions.labels.category',
    groupBy: (item) => item.groupName,
    renderGroup: defaultRenderGroup,
    filterSelectedOptions: false,
  },
];
