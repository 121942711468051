import {
  CreateResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';

import { PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { S3Uploader } from '@Helpers';
import { normalizeCategoryFilters } from '@Plugins/Catalogue/resources/Categories/components/AttachedFilters/utils';
import { errorHandler } from '@Helpers/ErrorHandler';

import { ResourceRoutes } from '../../../resourceRoutes';
import { sanitizePayload } from '../share/helpers/sanitizePayload';

import { DEFAULT_PAGE_FORM_VALUES } from './constants';
import { PageForm } from './PageForm';
import { ListPage } from './ListPage';
import { categoryCrud } from './api';
import { handleParentId } from './utils';

interface PreviewImage {
  url: string | { file: File; url: string };
}

const uploadImage = async (image?: PreviewImage) => {
  if (typeof image?.url === 'string') {
    return image?.url;
  }

  if (!image?.url?.file) {
    return '';
  }

  const uploader = new S3Uploader([image.url.file], 'catalog');
  const [url] = await uploader.send();

  return url;
};

async function formatCategoryDTO(
  data: Partial<
    Omit<CoreCategory, 'previewImage' | 'previewImageThumb'> & {
      previewImage: PreviewImage;
      previewImageThumb: PreviewImage;
    }
  >
) {
  const imageUrl = await uploadImage(data.previewImage);
  const imageThumbUrl = await uploadImage(data.previewImageThumb);

  if ((data.parentId as unknown as string) === '') {
    data.parentId = null;
  }

  if (data.pickingPriority) {
    data.pickingPriority = Number(data.pickingPriority);
  }

  return {
    ...data,
    parentId: data.parentId ?? null,
    previewImage: {
      url: imageUrl.length ? imageUrl : null,
    },
    previewImageThumb: {
      url: imageThumbUrl.length ? imageThumbUrl : null,
    },
  };
}

const CategoriesController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.catalog.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage
        type="create"
        includeForm={false}
        defaultFormValues={DEFAULT_PAGE_FORM_VALUES}
      >
        <PageForm />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage type="edit" includeForm={false} redirect={false}>
        <PageForm />
      </ResourceItemPage>
    ),
  },
  menuItem: {
    caption: {
      translationKey: 'catalogue.pages.categories.caption',
    },
    route: ResourceRoutes.catalog.routePath,
    icon: <Icon type="category" />,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      categoryCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await categoryCrud.getOne<{ data: CoreCategory }>(params);

      if (!result) {
        return { data: null };
      }

      let { data } = result;

      if (data.parentId) {
        const parentCategoryData = await handleParentId(data.parentId);

        if (parentCategoryData) {
          data = { ...data, ...parentCategoryData };
        }
      }

      return {
        data: {
          ...data,
          filters: data.filters.sort((a, b) => a.sorting - b.sorting),
        },
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      const data = await formatCategoryDTO(params.data);

      try {
        const result = await categoryCrud.create<{ data: CoreCategory }>({
          ...params,
          data: sanitizePayload(data),
        });

        if (result) {
          const slug = `${result.data.slug}-${result.data.id}`;

          await categoryCrud.update({
            id: result.data.id,
            data: { slug },
          });

          result.data.slug = slug;
        }

        return {
          data: result?.data,
        };
      } catch (error) {
        errorHandler(error);

        return { data: undefined };
      }
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await categoryCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      try {
        const data = await formatCategoryDTO(params.data);

        data.updatedAt = new Date().toISOString();

        await categoryCrud.update({
          ...params,
          data: sanitizePayload(normalizeCategoryFilters(data as CoreCategory)),
        });

        return {
          data: {
            ...data,
            filters: data.filters?.sort((a, b) => a.sorting - b.sorting),
          },
        };
      } catch (error) {
        errorHandler(error);

        return {
          data: null,
        };
      }
    },
  },
});

export default CategoriesController;
