export interface UserInfoProps {
  type: 'address' | 'namePhone';
  label?: string;
  hasPhoneFormat?: boolean;
}

export type RefundType = Nullable<'full' | 'partial'>;

export interface PartialRefundItem {
  sku: string;
  selectedProblemId: string;
  selectedQuantity: number;
  orderCurrentQuantity: number;
  typedComment: string;
  name: string;
  image: string;
  paidPricePerUnit: number;
  resultRefund: number;
  formattedUnitsRefund: string;
}

export enum PaymentMethod {
  Card = 'CARD',
  Cash = 'CASH',
}
