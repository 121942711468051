import { useCallback, useContext, useState, useMemo } from 'react';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { requestSupplyGapDelete, requestSupplyGapUpdate } from '../../utils';
import { Supplier } from '../../interface';
import {
  DirtyFieldValues,
  UseFocusedColumnReturnValue,
  FocusedColumnContextValue,
} from './interfaces';
import FocusedColumnContext from './context';

export const useFocusedColumnContext = () => {
  const value = useContext(FocusedColumnContext);

  if (!value) {
    throw new Error(
      '`useFocusedColumnContext` can be called only under FocusedColumnContextProvider'
    );
  }

  return value;
};

export const useFocusedColumn = (): UseFocusedColumnReturnValue => {
  const notify = useNotify();
  const refresh = useRefresh();
  const supplier = useRecordContext<Supplier>();

  const [focusedId, setFocusedId] = useState<Nullable<string>>(null);
  const [dirtyColumns, setDirtyColumns] = useState<DirtyFieldValues>({});

  const [nominatedToConfirmId, setNominatedToConfirmId] =
    useState<Nullable<string>>(null);

  const clearFocusedId = useCallback(() => {
    setFocusedId(null);
    setDirtyColumns({});
    setNominatedToConfirmId(null);
  }, [setFocusedId, setDirtyColumns, setNominatedToConfirmId]);

  const handleSuccess = useCallback(() => {
    notify('replenishment.pages.suppliers.messages.updateSuccess', {
      type: 'success',
    });
    refresh();
    clearFocusedId();

    if (nominatedToConfirmId) {
      setFocusedId(nominatedToConfirmId);
      setNominatedToConfirmId(null);
    }
  }, [
    nominatedToConfirmId,
    notify,
    refresh,
    clearFocusedId,
    setFocusedId,
    setNominatedToConfirmId,
  ]);

  const handleError = useCallback(
    (error: unknown) => {
      if (error instanceof AxiosError) {
        const status = error.response?.status;

        notify('replenishment.pages.suppliers.errors.failed', {
          type: 'error',
          autoHideDuration: 10000,
          messageArgs: { status },
        });
      }
    },
    [notify]
  );

  const { mutate: updateCellValue, isLoading: isUpdateLoading } = useMutation({
    mutationKey: 'updateCellValue',
    mutationFn: requestSupplyGapUpdate,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: deleteCellValue, isLoading: isDeleteLoading } = useMutation({
    mutationKey: 'deleteCellValue',
    mutationFn: requestSupplyGapDelete,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const handleSetFocusedId = useCallback(
    (id: Nullable<string>) => {
      if (!Object.keys(dirtyColumns).length) {
        setFocusedId(id);

        return;
      }

      setNominatedToConfirmId(id);
    },
    [focusedId, dirtyColumns]
  );

  const handleCloseConfirmModal = useCallback(() => {
    if (!nominatedToConfirmId || !focusedId) {
      return;
    }

    setDirtyColumns({});
    setFocusedId(nominatedToConfirmId);
    setNominatedToConfirmId(null);
  }, [
    nominatedToConfirmId,
    focusedId,
    setDirtyColumns,
    setFocusedId,
    setNominatedToConfirmId,
  ]);

  const confirmFieldFocusChange = useCallback(() => {
    if (!focusedId) {
      return;
    }

    const [rowId, weekDay] = focusedId.split('.');
    const targetRow = supplier.rows?.find((item) => item.id === rowId);

    if (!targetRow) {
      return;
    }

    const existingSchedule = targetRow[Number(weekDay)];

    const value = dirtyColumns[focusedId];

    if (!value) {
      if (existingSchedule) {
        deleteCellValue(existingSchedule.id);
      }

      return;
    }

    updateCellValue({
      id: existingSchedule?.id,
      store_id: targetRow.id,
      supplier_id: supplier.id,
      order_day: Number(weekDay) + 1,
      supply_gap: Number(value),
    });
  }, [focusedId, dirtyColumns, supplier, updateCellValue]);

  const contextValue = useMemo<FocusedColumnContextValue>(
    () => ({
      focusedId,
      setFocusedId: handleSetFocusedId,
      setFocusedId_raw: setFocusedId,
      dirtyColumns,
      setDirtyColumns,
      clearFocusedId,
      updateCellValue,
      deleteCellValue,
      isUpdating: isUpdateLoading || isDeleteLoading,
    }),
    [
      focusedId,
      handleSetFocusedId,
      setFocusedId,
      dirtyColumns,
      setDirtyColumns,
      clearFocusedId,
      updateCellValue,
      deleteCellValue,
      isUpdateLoading,
      isDeleteLoading,
      handleCloseConfirmModal,
    ]
  );

  return {
    contextValue,
    handleCloseConfirmModal,
    confirmFieldFocusChange,
    nominatedToConfirmId,
    setNominatedToConfirmId,
  };
};
