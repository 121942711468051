import { Navigate } from 'react-router-dom';

import { accessPermission } from '@Helpers/AccessPermissions/AccessPermissions';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { CompanyName } from '@Helpers/AccessPermissions/interface';

const Redirect = () => {
  if (
    accessPermission.isAdmin ||
    accessPermission.rolesList.includes('customer_support_specialist')
  ) {
    if (accessPermission.companyName === CompanyName.ArabianMile) {
      return <Navigate to={ResourceRoutes.deliveryOrders.routePath} />;
    }

    return <Navigate to={ResourceRoutes.orders.routePath} />;
  }

  if (accessPermission.rolesList.includes('warehouse_manager')) {
    return <Navigate to={ResourceRoutes.warehouse.routePath} />;
  }

  if (accessPermission.rolesList.includes('procurement_specialist')) {
    return <Navigate to={ResourceRoutes.catalog.routePath} />;
  }

  if (accessPermission.rolesList.includes('marketer')) {
    return <Navigate to={ResourceRoutes.promotions.promocodes.routePath} />;
  }

  if (accessPermission.rolesList.includes('replenishment_specialist')) {
    return <Navigate to={ResourceRoutes.replenishment.calculate.routePath} />;
  }

  return <></>;
};

export default Redirect;
