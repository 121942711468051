import { AutocompleteInputProps, useTranslate } from 'react-admin';
import { useController, FieldPath, FieldValues } from 'react-hook-form';

import { ResourceAutocompleteInput, ResourceAutocompleteArrayInput } from '@UI';
import { ResourceAutocompleteArrayInputProps } from '@UI/ResourceUI/ResourceAutocompleteInput/interface';
import { NestedCategory } from '@Hooks/useGroupedCategoryList';
import {
  GroupChildrenStyled,
  GroupedItemStyled,
  GroupLabelStyled,
} from '@Plugins/Catalogue/resources/Products/styled';
import { useMemo } from 'react';

export interface CategorySelectProps<Values extends FieldValues> {
  label?: string;
  source: FieldPath<Values>;
  options: NestedCategory[];
  multi?: boolean;
  validate?: ResourceAutocompleteArrayInputProps['validate'];
}

const CategorySelect = <Values extends FieldValues>(
  props: CategorySelectProps<Values>
) => {
  const {
    source,
    options,
    multi,
    label = 'catalogue.pages.products.labels.category',
    validate,
  } = props;
  const {
    field: { value: fieldValue },
  } = useController<Values>({ name: source });

  const translate = useTranslate();

  const selectedOption = useMemo(() => {
    if (!fieldValue) {
      return undefined;
    }

    return options.find((item) => item.id === fieldValue);
  }, [fieldValue, options]);

  const categoryLabel = translate(label);
  const dynamicLabel = selectedOption
    ? `${categoryLabel} - ${selectedOption.groupName}`
    : categoryLabel;

  const Component = multi
    ? ResourceAutocompleteArrayInput
    : ResourceAutocompleteInput;

  return (
    <Component
      name={source}
      source={source}
      label={dynamicLabel}
      choices={options.sort(sortNestedCategoriesBySelected(selectedOption))}
      translateChoice={false}
      fullWidth
      validate={validate}
      groupBy={(item) => item.groupName}
      renderGroup={defaultRenderGroup}
      filterSelectedOptions={false}
    />
  );
};

const sortNestedCategoriesBySelected =
  (selectedOption?: NestedCategory) =>
  (a: NestedCategory, b: NestedCategory) => {
    if (!selectedOption) {
      return 0;
    }

    const selectedA = a.groupName === selectedOption.groupName;
    const selectedB = b.groupName === selectedOption.groupName;

    if (selectedA && !selectedB) return -1;
    if (!selectedA && selectedB) return 1;

    return 0;
  };

export const defaultRenderGroup: AutocompleteInputProps['renderGroup'] = ({
  key,
  group,
  children,
}) => (
  <GroupedItemStyled key={key}>
    {/* eslint-disable-next-line */}
    {/* @ts-ignore */}
    <GroupLabelStyled component="div">{group}</GroupLabelStyled>
    <GroupChildrenStyled>{children}</GroupChildrenStyled>
  </GroupedItemStyled>
);

export default CategorySelect;
