import { useTranslate } from 'react-admin';

// TODO: take dynamic value from remote data
const DeliveryServiceColumn = () => {
  const translate = useTranslate();

  return <>{translate('delivery.pages.deliveryOrders.labels.ownDelivery')}</>;
};

export default DeliveryServiceColumn;
