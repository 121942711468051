import { useState, useEffect } from 'react';
import {
  useTranslate,
  Confirm,
  Validator,
  ValidationErrorMessageWithArgs,
  useRefresh,
  useRecordContext,
} from 'react-admin';
import { Button, Alert, Typography } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { useController, FieldPath } from 'react-hook-form';

import { Flex } from '@UI';
import { SelectOffersPopupContent } from '@Widgets/Bundles/SelectOffersPopupContent';

import { OffersViewTable } from './components/OffersViewTable';
import { LinkedEmpty } from './components/LinkedEmpty';

export interface ProductOffersProps {
  source: FieldPath<CoreProduct>;
  validate?: Validator | Validator[];
}

export const ProductOffers = (props: ProductOffersProps) => {
  const { validate, source } = props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const record = useRecordContext();

  const {
    field: offersField,
    fieldState: { error: offersError },
  } = useController<CoreProduct, FieldPath<CoreProduct>>({
    name: source,
    rules: {
      validate: (value, formValues) => {
        if (!validate) {
          return;
        }

        const validators = Array.isArray(validate) ? validate : [validate];

        for (const validator of validators) {
          const errorResult = validator(value, formValues, props);

          if (!errorResult) {
            continue;
          }

          if (typeof errorResult === 'string') {
            return translate(errorResult);
          }

          const errorInstance = errorResult as ValidationErrorMessageWithArgs;

          return translate(errorInstance.message, errorInstance.args);
        }
      },
    },
  });

  const [isLinkOfferModalOpen, setIsLinkOfferModalOpen] =
    useState<boolean>(false);
  const [selectedOffers, setSelectedOffers] = useState<CoreOffer[]>([]);

  const getOffers = (): CoreOffer[] => {
    if (!offersField.value) {
      return [];
    }

    return offersField.value as CoreOffer[];
  };

  const offers = getOffers();

  const handleCloseLinkModal = () => {
    setIsLinkOfferModalOpen(false);
    setSelectedOffers([]);
  };

  const onSelect = (selected: Record<number, CoreOffer>) => {
    setSelectedOffers(Object.values(selected));
  };

  const getIsRowSelectable = (id: number) => {
    // call getOffers to update scope of form values
    return !getOffers().some((item) => item.id === id);
  };

  const onConfirm = () => {
    if (!selectedOffers.length) {
      return;
    }

    // call getOffers to update scope of form values
    offersField.onChange([...getOffers(), ...selectedOffers]);
    handleCloseLinkModal();
  };

  const handleOpenOfferSelectionDialog = () => {
    setIsLinkOfferModalOpen(true);
  };

  useEffect(() => {
    // Refetch product's offer info to actualize statuses
    refresh();
  }, [refresh, record]);

  return (
    <>
      {offers.length > 0 && (
        <>
          <Flex
            asColumn={false}
            justifyContent="flex-end"
            fullWidth
            sx={{ mb: 3 }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<LinkIcon />}
              onClick={handleOpenOfferSelectionDialog}
            >
              {translate('catalogue.pages.products.actions.linkOffers')}
            </Button>
          </Flex>

          <OffersViewTable offers={offers} />
        </>
      )}

      {!offers.length && (
        <LinkedEmpty
          message="catalogue.pages.products.messages.emptyOffers"
          invite="catalogue.pages.products.messages.emptyOffersInvite"
          action="catalogue.pages.products.actions.linkOffers"
          onClick={handleOpenOfferSelectionDialog}
        />
      )}

      {offersError && (
        <Alert severity="error" variant="outlined">
          <Typography>{offersError.message}</Typography>
        </Alert>
      )}

      <Confirm
        title={translate('catalogue.pages.products.labels.selectOffers')}
        isOpen={isLinkOfferModalOpen}
        onClose={handleCloseLinkModal}
        fullWidth
        maxWidth="xl"
        keepMounted={false}
        content={
          <SelectOffersPopupContent
            selectedOffers={selectedOffers}
            onSelect={onSelect}
            isRowSelectable={getIsRowSelectable}
          />
        }
        onConfirm={onConfirm}
      />
    </>
  );
};
