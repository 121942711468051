import { PluginName, PluginMenu, CustomRoute } from '@PluginManager/interface';
import Plugins from '@Plugins';
import { ResourceProps, GetListParams } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { TranslationMerger } from './base/TranslationMerger/TranslationMerger';
import { AppDataProviderInterface } from '../providers/dataProvider/interface';
import {
  DataProvider,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  CreateParams,
  DeleteParams,
  DeleteManyParams,
  UpdateParams,
  UpdateManyParams,
  TranslationMessages,
  I18nProvider,
} from 'react-admin';
import getDataProvider from '../providers/dataProvider';
import { accessPermission } from '@Helpers';
import { SupportedLanguage } from '@ROOT/interface';

import englishMessages from '../i18n/en';
import arabicMessages from '../i18n/ar';

export class PluginManager {
  private readonly _i18nMerger: TranslationMerger = new TranslationMerger();
  private _availablePlugins: PluginName[] = [];
  private _menu: PluginMenu[] = [];
  private _customRoutes: CustomRoute[] = [];
  private _resourceRoutes: ResourceProps[] = [];
  private readonly _coreDataProvider: AppDataProviderInterface;
  private _customDataProviders: Record<
    string,
    Nullable<Partial<DataProvider>>
  > = {};

  private _i18nProvider: Nullable<I18nProvider> = null;

  constructor(dataProvider: AppDataProviderInterface) {
    this._coreDataProvider = dataProvider;
  }

  get pluginsTranslations() {
    return this._i18nMerger.i18n;
  }

  get localization(): Record<SupportedLanguage, TranslationMessages> {
    return {
      en: {
        ...englishMessages,
        ...this.pluginsTranslations.en,
      },
      ar: {
        ...arabicMessages,
        ...this.pluginsTranslations.ar,
      },
    };
  }

  get pluginsMenu(): PluginMenu[] {
    return this._menu;
  }

  get expandedMenuState(): Record<string, boolean> {
    return this.pluginsMenu.reduce<Record<string, boolean>>(
      (acc, { rootMenu }) => {
        if (!rootMenu) return acc;
        acc[rootMenu.caption.translationKey] = false;

        return acc;
      },
      {}
    );
  }

  get dataProvider(): AppDataProviderInterface {
    // TODO: add ability to use custom methods of _customDataProviders
    const crudMappedByPlugins: DataProvider = {
      getList: (resource: string, params: GetListParams) => {
        return this.getDataProviderHandler('getList', resource)(
          resource,
          params
        );
      },
      getOne: (resource: string, params: GetOneParams) => {
        return this.getDataProviderHandler('getOne', resource)(
          resource,
          params
        );
      },
      getMany: (resource: string, params: GetManyParams) => {
        return this.getDataProviderHandler('getMany', resource)(
          resource,
          params
        );
      },
      getManyReference: (resource: string, params: GetManyReferenceParams) => {
        return this.getDataProviderHandler('getManyReference', resource)(
          resource,
          params
        );
      },
      create: (resource: string, params: CreateParams) => {
        return this.getDataProviderHandler('create', resource)(
          resource,
          params
        );
      },
      delete: (resource: string, params: DeleteParams) => {
        return this.getDataProviderHandler('delete', resource)(
          resource,
          params
        );
      },
      deleteMany: (resource: string, params: DeleteManyParams) => {
        return this.getDataProviderHandler('deleteMany', resource)(
          resource,
          params
        );
      },
      update: (resource: string, params: UpdateParams) => {
        return this.getDataProviderHandler('update', resource)(
          resource,
          params
        );
      },
      updateMany: (resource: string, params: UpdateManyParams) => {
        return this.getDataProviderHandler('updateMany', resource)(
          resource,
          params
        );
      },
    };

    return {
      ...this._coreDataProvider,
      ...crudMappedByPlugins,
    };
  }

  get customRoutes(): CustomRoute[] {
    return this._customRoutes;
  }

  get resourceRoutes(): ResourceProps[] {
    return this._resourceRoutes;
  }

  get i18nProvider(): I18nProvider {
    if (!this._i18nProvider) {
      this._initializeI18nProvider();
    }

    // eslint-disable-next-line
    return this._i18nProvider!;
  }

  checkIsPluginAvailable(pluginName: PluginName): boolean {
    return this._availablePlugins.includes(pluginName);
  }

  init(availablePlugins: PluginName[]) {
    this.resetManager();
    this._availablePlugins = availablePlugins;

    availablePlugins.forEach((pluginName) => {
      const plugin = Plugins[pluginName];

      this._i18nMerger.mergeI18N(plugin.i18n);
      this._menu.push(plugin.menu);
      this._customRoutes.push(...plugin.customRoutes);
      this._resourceRoutes.push(...plugin.resourceRoutes);

      this._customDataProviders = {
        ...this._customDataProviders,
        ...plugin.dataProviders,
      };
    });

    this._menu = accessPermission.checkPages(this._menu);
    this._initializeI18nProvider();
  }

  private getDataProviderHandler(
    handlerName: keyof DataProvider,
    resource: string
  ) {
    const handler =
      this._customDataProviders[resource]?.[handlerName] ||
      this._coreDataProvider?.[handlerName];

    if (handler) {
      return handler;
    }

    throw new Error(
      `DataProvider ${handlerName} error for resource ${resource}`
    );
  }

  private resetManager() {
    this._i18nMerger.reset();
    this._menu = [];
    this._customRoutes = [];
    this._resourceRoutes = [];
  }

  private _initializeI18nProvider() {
    const locales = [
      { locale: 'en', name: 'English' },
      { locale: 'ar', name: 'Arabic' },
    ];

    this._i18nProvider = polyglotI18nProvider(
      (lang) => {
        if (lang in this.localization) {
          return this.localization[lang as SupportedLanguage];
        }

        // Always fallback to english
        return this.localization.en;
      },
      this._loadDefaultLanguage(),
      locales
    );
  }

  private _loadDefaultLanguage() {
    if (!accessPermission.company?.languageChangingAvailable) {
      return 'en';
    }

    const stored = localStorage.getItem('RaStore.locale');

    if (!stored) {
      return 'en';
    }

    return stored.trim().replace('"', '');
  }
}

const pluginManager = new PluginManager(getDataProvider);

export default pluginManager;
