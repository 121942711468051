import { D } from '@mobily/ts-belt';

import { RequestAPI } from '@RestApi';
import { CommonCrudOptions } from '@PluginManager/base/AbstractApi/CommonCrud';
import { errorHandler } from '@Helpers/ErrorHandler';

import { SUBSCRIPTIONS_SETTINGS_API } from './constants';
import {
  ResponseData,
  StockSubscriptionResp,
  SubscriptionsSettinsConfig,
} from './interfaces';

class SubscriptionsSettingsService {
  protected apiBase: string;
  protected errorHandler?: (error: Error) => void;

  constructor(apiBase: string, options?: CommonCrudOptions) {
    this.apiBase = apiBase;
    this.errorHandler = options?.errorHandler;
  }

  getCompanySubscriptionConfig = async (
    urlPart = ''
  ): Promise<ResponseData<CoreStockSubscription>> => {
    const url = this._buildUrl(urlPart);

    try {
      const response = await RequestAPI.get(url);

      return {
        data: response,
      };
    } catch (error: unknown) {
      this._handleError(error);

      return {
        data: null,
      };
    }
  };

  createSubscriptionSettings = async (
    data: Partial<SubscriptionsSettinsConfig>
  ): Promise<ResponseData<StockSubscriptionResp>> => {
    const url = this._buildUrl('');
    const instance = D.deleteKey(data, 'id');

    instance.reminderCount = +(instance.reminderCount ?? 0);

    try {
      const response = await RequestAPI.post(url, instance);

      return {
        data: {
          id: 'unknown',
          settings: response.data,
        },
      };
    } catch (error: unknown) {
      this._handleError(error);

      return {
        data: null,
      };
    }
  };

  updateSubscriptionSettings = async (
    id: string,
    data: Partial<SubscriptionsSettinsConfig>,
    urlPart = ''
  ): Promise<ResponseData<StockSubscriptionResp>> => {
    const url = this._buildUrl(urlPart === '' ? id : `${urlPart}/${id}`);
    let instance = D.deleteKey(data, 'id');

    instance = D.deleteKey(instance, 'companyId');
    instance.reminderCount = +(instance.reminderCount ?? 0);

    try {
      const response = await RequestAPI.put(url, instance);

      return {
        data: {
          id: 'unknown',
          settings: response.data,
        },
      };
    } catch (error: unknown) {
      this._handleError(error);

      return {
        data: null,
      };
    }
  };

  private readonly _buildUrl = (part: string): string => {
    if (this.apiBase.endsWith('/')) {
      this.apiBase = this.apiBase.substring(0, this.apiBase.length - 1);
    }

    return `${this.apiBase}/${part}`;
  };

  private readonly _handleError = (error: unknown) => {
    if (!this.errorHandler) {
      errorHandler(error);

      return;
    }

    this.errorHandler(error as Error);
  };
}

export const subscriptionsSettingsService = new SubscriptionsSettingsService(
  SUBSCRIPTIONS_SETTINGS_API
);
