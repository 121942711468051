import { useRecordContext } from 'react-admin';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { RequestAPI } from '@RestApi';
import { SELECTED_ZONE_QUERY_NAME } from '@Plugins/Stores/resources/Warehouses/pages/ItemPage/PageForm/tabs/DeliveryAreas/constants';
import { DELIVERY_AREA_API } from '@Plugins/Stores/resources/Warehouses/apiRoutes';

const DeliveryZoneLinkedField = () => {
  const record = useRecordContext<CoreOrder>();

  const { data: deliveryAreaInstance, isLoading } = useQuery({
    queryKey: 'orderDeliveryZoneDetail',
    queryFn: async () => {
      const response = await RequestAPI.get(
        `${DELIVERY_AREA_API}/${record.delivery_zone_id}`
      );

      return response.data;
    },
    cacheTime: 0,
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    keepPreviousData: false,
  });

  if (isLoading || !deliveryAreaInstance) {
    return <>{record.delivery_zone_name}</>;
  }

  return (
    <MuiLink
      component={Link}
      to={`/warehouses/${deliveryAreaInstance.warehouse_id}/2?${SELECTED_ZONE_QUERY_NAME}=${deliveryAreaInstance.id}`}
    >
      {record.delivery_zone_name}
    </MuiLink>
  );
};

export default DeliveryZoneLinkedField;
