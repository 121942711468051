import { useTranslate } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import { FocusedColumnContextProviderProps } from './interfaces';
import { useFocusedColumn } from './hooks';
import FocusedColumnContext from './context';

const FocusedColumnContextProvider = ({
  children,
}: FocusedColumnContextProviderProps) => {
  const translate = useTranslate();

  const {
    handleCloseConfirmModal,
    confirmFieldFocusChange,
    contextValue,
    nominatedToConfirmId,
  } = useFocusedColumn();

  return (
    <FocusedColumnContext.Provider value={contextValue}>
      {children}

      <Dialog
        open={Boolean(nominatedToConfirmId)}
        onClose={handleCloseConfirmModal}
      >
        <DialogTitle>
          {translate('replenishment.pages.suppliers.labels.confirmAction')}
        </DialogTitle>
        <DialogContent>
          {translate(
            'replenishment.pages.suppliers.messages.confirmFieldClose'
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={contextValue.isUpdating}
            color="error"
            onClick={handleCloseConfirmModal}
          >
            {translate('replenishment.pages.suppliers.actions.discard')}
          </Button>
          <Button
            disabled={contextValue.isUpdating}
            variant="contained"
            onClick={confirmFieldFocusChange}
          >
            {translate('ra.action.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </FocusedColumnContext.Provider>
  );
};

export default FocusedColumnContextProvider;
