import { Validator } from 'react-admin';

import {
  ValidationImageValue,
  AspectRationValidationMessages,
} from './interfaces';
import { getAspectRatio, getImageNaturalWidthHeight } from './utils';

export const validateAspectRatio =
  (
    targetRatio: number,
    messages?: AspectRationValidationMessages,
    opts?: { allowPortrait?: boolean }
  ): Validator =>
  async (value: ValidationImageValue | string | null) => {
    if (!value) {
      return;
    }

    const {
      ratioError = 'ra.validation.invalidAspectRatio',
      landscapeError = 'ra.validation.landscapeRequired',
      imageError = 'ra.validation.imageLoadError',
    } = messages || {};

    const { allowPortrait = false } = opts || {};

    const src = typeof value === 'string' ? value : value.url;

    let width = 0;
    let height = 0;

    try {
      const naturalSize = await getImageNaturalWidthHeight(src);

      width = naturalSize.width;
      height = naturalSize.height;
    } catch (error) {
      return imageError;
    }

    if (!allowPortrait && height > width) {
      return landscapeError;
    }

    const formattedTargetRatio = Number(targetRatio.toFixed(2));
    const { ratio } = getAspectRatio(width, height);

    if (formattedTargetRatio !== ratio) {
      const targetHeight = Math.round(width / targetRatio);

      return { message: ratioError, args: { width, height: targetHeight } };
    }

    return undefined;
  };
