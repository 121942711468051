import MapIcon from '@mui/icons-material/Map';

import { Plugin } from '@PluginBase';

import DeliveryDispatcherController from './resources/DeliveryDispatcher/controller';
import DeliveryOrders from './resources/DeliveryOrders/controller';

import Translation from './i18n.json';

const Delivery = new Plugin({
  name: 'Delivery',
  resources: [DeliveryOrders, DeliveryDispatcherController],
  i18n: Translation,
  rootMenu: {
    caption: {
      translationKey: 'delivery.caption',
    },
    icon: <MapIcon />,
  },
});

export default Delivery;
