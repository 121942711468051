import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ListItemDrawer } from '@Widgets/ListItemDrawer/ListItemDrawer';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ShowFromContainerStyled } from '@Widgets/styles';

import { OrderDetails } from '../OrderDetails';
import { LIST_COLUMNS, LIST_FILTERS } from './constants';

const ListPage = () => {
  return (
    <>
      <ResourceList
        columns={LIST_COLUMNS}
        filters={LIST_FILTERS}
        getItemCardCaption={(item) => item.id}
        // eslint-disable-next-line
        rowClick={(_, __, record) =>
          `${ResourceRoutes.deliveryOrders.routePath}/${record.order_uuid}`
        }
        defaultSort={{
          field: 'created_at',
          order: 'DESC',
        }}
      />

      <ListItemDrawer route={ResourceRoutes.deliveryOrders.routePath}>
        {({ entity }) =>
          entity ? (
            <ShowFromContainerStyled id={entity} emptyWhileLoading>
              <OrderDetails />
            </ShowFromContainerStyled>
          ) : (
            <></>
          )
        }
      </ListItemDrawer>
    </>
  );
};

export default ListPage;
