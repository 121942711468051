import { RowInfoBlock, Flex } from '@UI';
import { TextInput } from 'react-admin';

import { maxLengthExt, accessPermission } from '@Helpers';

export const AdditionalLanguage = () => {
  return (
    <Flex asColumn fullWidth>
      <RowInfoBlock
        flex={1}
        titleKey="catalogue.pages.categories.labels.nameAr"
        fullWidth
      >
        <TextInput
          source="nameAr"
          name="nameAr"
          label="catalogue.pages.categories.labels.nameAr"
          fullWidth
          validate={maxLengthExt(246)}
        />
      </RowInfoBlock>

      {accessPermission.company?.categoryHaveDescription && (
        <RowInfoBlock
          flex={1}
          titleKey="catalogue.pages.categories.labels.descriptionAr"
          fullWidth
        >
          <TextInput
            multiline
            fullWidth
            name="descriptionAr"
            source="descriptionAr"
            label="catalogue.pages.categories.labels.descriptionAr"
            rows={5}
          />
        </RowInfoBlock>
      )}
    </Flex>
  );
};
