import { Icon } from '@UI';
import { CommonCrud, PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { ListPage } from './pages/ListPage';
import { API_ENDPOINT } from './constants';
import { GetListResult } from 'react-admin';

const customerCrud = new CommonCrud(API_ENDPOINT, { isNewSorting: true });

customerCrud.isNewSorting = true;

const SubscriptionsController = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.subscriptions.notify_me.routePath,
    caption: {
      translationKey: 'subscriptions.pages.subscriptions.caption',
    },
    icon: <Icon type="notifications" />,
  },
  resourceRoute: {
    name: ResourceRoutes.subscriptions.notify_me.resourcePath,
    list: <ListPage />,
  },
  dataProvider: {
    getList: async (src, params) => {
      const result = await customerCrud.list(params);

      return {
        total: result.total,
        data: result.data.map((i, idx) => ({ ...i, id: idx })),
      } as GetListResult;
    },
  },
});

export default SubscriptionsController;
