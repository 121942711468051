import { ListColumn, Filter } from '@Widgets/ResourceList/interface';
import { PhoneField } from '../components/PhoneField';

export const LIST_COLUMNS: ListColumn<CoreUser>[] = [
  {
    source: 'email',
    label: 'settings.pages.users.labels.email',
    type: 'text',
    sortable: false,
  },
  {
    source: 'phone',
    label: 'settings.pages.users.labels.phone',
    type: 'custom',
    sortable: false,
    customComponent: <PhoneField hasPhoneFormat={false} />,
  },
  {
    source: 'login',
    label: 'settings.pages.users.labels.login',
    type: 'text',
    sortable: false,
  },
  {
    // TODO: fix this "source" value in type definition
    // @ts-ignore
    source: 'rolesFlat',
    label: 'settings.pages.users.labels.roles',
    type: 'text',
    sortable: false,
  },
  {
    source: 'firstName',
    label: 'settings.pages.users.labels.firstName',
    type: 'text',
    sortable: false,
  },
  {
    source: 'lastName',
    label: 'settings.pages.users.labels.lastName',
    type: 'text',
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'settings.pages.users.labels.createdAt',
    type: 'date',
    showTime: true,
  },
];

export const LIST_FILTERS: Filter[] = [
  {
    source: 'search',
    type: 'search',
  },
];
